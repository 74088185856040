<template>
	<div v-if="new Array('Admin', 'SuperAdmin').includes(profile.role)">
		<v-card class="my-3">
			<v-tabs v-model="tab" left prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right" show-arrows>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-tab v-bind="attrs" v-on="on">
							<v-icon>mdi-export-variant</v-icon>
						</v-tab>
					</template>
					<span>Telecharger des logs au format excel</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-tab v-bind="attrs" v-on="on">
							<v-icon>mdi-glasses</v-icon>
						</v-tab>
					</template>
					<span>Voir Les logs utilisateurs de la room</span>
				</v-tooltip>
			</v-tabs>

			<v-card-text>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<LogExtractionTab :profile="profile" :user="user"></LogExtractionTab>
					</v-tab-item>
					<v-tab-item>
						<LogViewerTab :profile="profile" :user="user"></LogViewerTab>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import LogExtractionTab from '@/components/Logs/LogExtractionTab.vue'
import LogViewerTab from '@/components/Logs/LogViewerTab.vue'

export default {
	name: "Logs",
	props: ["user", "profile", "notifications", "config", "currentRoom"],
	components: {
		LogExtractionTab,
		LogViewerTab,
	},
	data() {
		return {
			tab: 0,
		}
	},
	created() {

	},
	methods: {

	},
}
</script>

<template>
	<div>
		<v-card class="my-3">
			<v-card-title>Extraire les logs</v-card-title>
            <v-card-text class="my-3">
                <div v-if="profile.role == 'SuperAdmin'">
                    <v-btn color="error" @click="getAllLogs">Extraire tout les logs</v-btn>
                    <p class="mt-3 error--text">ATTENTION : cette action peut prendre plusieure minutes et ralentir fortement les services.</p>
                </div>
                <v-divider class="my-10"></v-divider>
                <div v-if="profile.role == 'SuperAdmin'">
                    <v-btn color="warning" @click="getRoomLogs">Extraire les logs de la Room</v-btn>
                    <p class="mt-3 error--text">ATTENTION : cette action peut prendre plusieure minutes et ralentir fortement les services.</p>
                </div>
                <v-divider class="my-10"></v-divider>
                <div>
                    <v-select solo class="mb-5" :items="profiles" item-value="id" v-model="currentProfile" label="Utilisateur">
                        <template slot="item" slot-scope="data">
                            <UserAvatar :profile="data.item"></UserAvatar>

                            <span class="pl-3" v-text="data.item.firstname + ' ' + data.item.lastname + ' (' + data.item.role + ')'"></span>
                        </template>
                        <template slot="selection" slot-scope="data">
                            <UserAvatar :profile="data.item"></UserAvatar>
                        
                            <span class="pl-3" v-text="data.item.firstname + ' ' + data.item.lastname + ' (' + data.item.role + ')'"></span>
                        </template>
                    </v-select>
                    <v-btn color="primary" @click="getUserLogs">Extraire les logs de l'utilisateur</v-btn>
                    <p class="mt-3 error--text">ATTENTION : cette action peut prendre plusieure minutes et ralentir fortement les services.</p>
                </div>
            </v-card-text>
        </v-card>
	</div>
</template>

<script>
    import logger from "@/assets/functions/logger"
    
    import Profile from '@/classes/Profile'
    import UserAvatar from '../UserAvatar.vue';

	export default {
		name: "LogExtractionTab",
		props: ["user", "profile"],
		components: {
            UserAvatar
        },
        data() {
            return {
                currentProfile: null,
                profiles : [],
                unsub: [],
            }
        },
        created() {
            if(this.profile.role == "SuperAdmin") {
                this.unsub.push(Profile.listenAll((profiles) => {
					let tmp_profiles = []
                    profiles.forEach((profile) => {
                        tmp_profiles.push(profile)
                    })
                    tmp_profiles.sort((a, b) => {
                        if(a.role < b.role) return 1
                        else if(a.role > b.role) return -1
                        else if(a.lastname < b.lastname) return -1
                        else if(a.lastname > b.lastname) return 1
                        else if(a.firstname < b.firstname) return -1
                        else if(a.firstname > b.firstname) return 1
                        else return 0
                    })
                    this.profiles = tmp_profiles
                }))
            }else{
                this.unsub.push(Profile.listenByRoom(this.profile.currentRoom, (profiles) => {
                    let tmp_profiles = []
                    profiles.forEach((profile) => {
                        if(profile.role == "User") {
                            tmp_profiles.push(profile)
                        }
                        tmp_profiles.sort((a, b) => {
                            if(a.role < b.role) return 1
                            else if(a.role > b.role) return -1
                            else if(a.lastname < b.lastname) return -1
                            else if(a.lastname > b.lastname) return 1
                            else if(a.firstname < b.firstname) return -1
                            else if(a.firstname > b.firstname) return 1
                            else return 0
                        })
                    })
                    this.profiles = tmp_profiles
                }))
            }
        },
        methods: {
            getAllLogs() {
                logger.log(this.profile.id, "LOGS EXTRACTION", this.profile.email + " a extrait tout les logs")
                logger.extractAllLogs()
            },
            getUserLogs() {
                logger.log(this.profile.id, "LOGS EXTRACTION", this.profile.email + " a extrait les logs de " + this.currentProfile)
                logger.extractProfileLogs(this.currentProfile)
            },
            getRoomLogs() {
                logger.log(this.profile.id, "LOGS EXTRACTION", this.profile.email + " a extrait les logs de la salle " + this.profile.currentRoom)
                logger.extractRoomLogs(this.profile.currentRoom)
            }
        },
	}
</script>

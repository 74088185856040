<template>
	<div>
		<v-card class="my-3">
			<v-card-title>Voir les logs (Utilisateurs uniquement)</v-card-title>
            <v-card-text class="my-3">
                <v-data-table :headers="headers" :items="logs" item-key="id" :search="search">
                    <template v-slot:top>
                        <v-text-field v-model="search" label="Search" class="mx-4" />
                    </template>
                    <template v-slot:item.timestamp="{ item }">
                        <span v-text="new Date(item.timestamp).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' ' + new Date(item.timestamp).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })"></span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
	</div>
</template>

<script>

    import Log from "@/classes/Log"
    import Profile from '@/classes/Profile'

	export default {
		name: "LogViewerTab",
		props: ["user", "profile"],
		components: {
		},
        data() {
            return {
                headers: [
                    {
                        text: "profileId",
                        value: "profileId",
                        sortable: true,
                    },
                    {
                        text: "type",
                        value: "type",
                        sortable: true,
                    },
                    {
                        text: "message",
                        value: "message",
                        sortable: true,
                    },
                    {
                        text: "Date",
                        value: "timestamp",
                        sortable: true,
                    },
                ],
                userProfilesId: [],
                logs : [],
                search: "",
                unsub: [],
            }
        },
        async created() {
            if(this.profile && this.profile.role && new Array('Admin','SuperAdmin').includes(this.profile.role)){
                let tmpProfiles = await Profile.getByRoom(this.profile.currentRoom)
                tmpProfiles.forEach(profile => {
                    this.userProfilesId.push(profile.id)
                })
                
                this.unsub.push(
                    Log.listenAll( logs => {
                        for(let log of logs){
                            if(this.userProfilesId.includes(log.profileId)){
                                this.logs.push(log)
                            }
                        }
                        this.logs.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1)
                    })
                )
            }
        },
        methods: {
            
        },
	}
</script>
